import styled from "styled-components";

const CheckBox = styled.div`
  > input {
    opacity: 0;
  }
  > input + label {
    position: relative;
    padding-left: 1.5rem;
    cursor: pointer;
    &:before {
      content: '';
      position: absolute;
      left:0;
      top: 0;
      width: 1.25rem;
      height: 1.25rem;
      border: 1px solid #D2D2D2;
      background: #ffffff;
      border-radius: 50%;
    }
    &:after {
      content: '';
      background: url('/images/ic_checked.png');
      width: 1.25rem;
      height: 1.25rem;
      position: absolute;
      top: 0;
      left: 0;
      transition: all .2s;
      background-repeat: no-repeat;
      background-size: 1.25rem;
    }
  }
  > input:not(:checked) + label {
      &:after {
        opacity: 0;
        transform: scale(0);
      }
  }

`;

export default CheckBox;