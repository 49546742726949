import styled from 'styled-components';

const Spinner = styled.div`
  position: relative;
  width: 5rem;
  height: 5rem;
  display: block;
  margin: 0 auto;
  div {
    width: 6%;
    height: 16%;
    background: #FFF;
    position: absolute;
    left: 49%;
    top: 43%;
    opacity: 0;
    -webkit-border-radius: 50px;
    -webkit-box-shadow: 0 0 3px rgba(0,0,0,0.2);
    -webkit-animation: fade 1s linear infinite;
  }
  .bar1 {
    -webkit-transform:rotate(0deg) translate(0, -130%);
    -webkit-animation-delay: 0s;
  }    
  
  .bar2 {
    -webkit-transform:rotate(30deg) translate(0, -130%); 
    -webkit-animation-delay: -0.9167s;
  }
  
  .bar3 {
    -webkit-transform:rotate(60deg) translate(0, -130%); 
    -webkit-animation-delay: -0.833s;
  }
  .bar4 {
    -webkit-transform:rotate(90deg) translate(0, -130%); 
    -webkit-animation-delay: -0.7497s;
  }
  .bar5 {
    -webkit-transform:rotate(120deg) translate(0, -130%); 
    -webkit-animation-delay: -0.667s;
  }
  .bar6 {
    -webkit-transform:rotate(150deg) translate(0, -130%); 
    -webkit-animation-delay: -0.5837s;
  }
  .bar7 {
    -webkit-transform:rotate(180deg) translate(0, -130%); 
    -webkit-animation-delay: -0.5s;
  }
  .bar8 {
    -webkit-transform:rotate(210deg) translate(0, -130%); 
    -webkit-animation-delay: -0.4167s;
  }
  .bar9 {
    -webkit-transform:rotate(240deg) translate(0, -130%); 
    -webkit-animation-delay: -0.333s;
  }
  .bar10 {
    -webkit-transform:rotate(270deg) translate(0, -130%); 
    -webkit-animation-delay: -0.2497s;
  }
  .bar11 {
    -webkit-transform:rotate(300deg) translate(0, -130%); 
    -webkit-animation-delay: -0.167s;
  }
  .bar12 {
    -webkit-transform:rotate(330deg) translate(0, -130%); 
    -webkit-animation-delay: -0.0833s;
  }
`
export default Spinner