import styled from 'styled-components';
import { colorGrey } from '../variables';
import { space, color, SpaceProps, ColorProps, typography, TypographyProps } from 'styled-system'
import { device } from '../helpers';

interface TextProps extends SpaceProps, ColorProps, TypographyProps {
  header?: boolean,
  second?: boolean,
  usd?: boolean,
  blockNotice?: boolean
}

const TextBold = styled.div<TextProps>`
  display: inline;
  font-weight: 700;
  ${color}
  ${typography}
  ${space}

  ${({second}) => second && `
    display: block;
    margin-bottom: 1rem;
  `}

  ${({blockNotice}) => blockNotice && `
    display: block;
    line-height: 1.313rem;
    letter-spacing: 0.013rem;
  `}

  span {
    font-weight: 400;
  }

  ${({usd}) => usd && `
    margin-left: 20.5px;
    margin-right: 20.5px;
  `}

  @media ${device.mobileS} {
    ${({header}) => header && `
      font-size: 1.75rem;
    `}
  }

  @media (max-width: 320px) {
    ${({usd}) => usd && `
      margin-left: 0.5px;
      margin-right: 0.5px;
    `}
  }
`;

TextBold.defaultProps = {
  color: colorGrey,
  fontSize: '1rem'
}

export default TextBold