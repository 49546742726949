export const fs = (sizePx: number) => `
  font-size: ${sizePx / 16}rem;
`;

export const rem = (sizePx: number) => `${sizePx / 16}rem`;

const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
}

export const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
};