import React from 'react';
import { Container as C } from "@src/styled/App"
import { TextBold } from '@src/styled/components';

const Header: React.FC = () => {
  return (
    <C.SpaceShadow position="relative" py={12} bg="#F57600" id="top-header">
      <TextBold color="#FFFFFF">FX体験へようこそ</TextBold>
    </C.SpaceShadow>
  )
}

export default Header;