import styled from 'styled-components';

const CircleText = styled.span`
  border: 1px solid #4D4D4D;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  font-size: 12px;
  font-weight: 700;
  justify-content: center;
  align-items: center;
  margin-left: 6px;
  margin-right: 4px;
`;

export default CircleText;