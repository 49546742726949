import styled from "styled-components";
import { fs, rem } from "../helpers";
import { colorWhite } from "../variables";

const Body = styled.div`
  background: #323338;
  padding: 1.125rem 1rem 1.5rem;
  color: ${colorWhite};
  ${fs(40)};
  .calculator__keys {
    font-family: Oxygen, sans-serif;
    background-color: #4C4E53;
    display: grid;
    grid-gap: 1px;
    grid-template-columns: repeat(4, 1fr);
    border: 1px solid #4C4E53;
  }
  .calculator__keys > * {
    background-color: #323338;
    position: relative;
    text-align: center;
  }
  .key--operator {
    background-color: #323338;
  }

  .key--zero {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .key--equal {
    background: #EB551D;
    grid-column: -2;
    grid-row: 3 / span 4;
  }
`;

const Result = styled.div`
  font-family: Oxygen, sans-serif;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: ${rem(88)};
  border: 1px solid #4C4E53;
  color: #fff;
  background: #000000;
  padding: 0 .5rem;
`

export default {
  Body,
  Result
};
