import React from 'react';
import { Spinner as SpinnerStyled } from '@src/styled/components';
import Modal from 'react-modal';

interface IProps {
  classOverlay?: string
}

const Spinner: React.FC<IProps> = ({ classOverlay }) => {
  return (
    <Modal
      closeTimeoutMS={500}
      isOpen={true}
      className="Helping_Question_Modal"
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      ariaHideApp={false}
      overlayClassName={classOverlay}
    >
      <SpinnerStyled>
        <div className="bar1"></div>
        <div className="bar2"></div>
        <div className="bar3"></div>
        <div className="bar4"></div>
        <div className="bar5"></div>
        <div className="bar6"></div>
        <div className="bar7"></div>
        <div className="bar8"></div>
        <div className="bar9"></div>
        <div className="bar10"></div>
        <div className="bar11"></div>
        <div className="bar12"></div>
      </SpinnerStyled>
    </Modal>
  )
}

export default React.memo(Spinner);