import styled from 'styled-components';
import { space, layout, typography, TypographyProps,SpaceProps, LayoutProps } from 'styled-system'

const iconHint = ('/images/icon_hint.png');
const btnSell = ('/images/btn_sell.png');
const btnBuy = ('/images/btn_buy.png');
const btnOP = ('/images/btn-op.png');

import {
  colorOrange,
  colorOrangeLight,
  colorWhite,
  colorGrey,
  colorGreyLight,
  colorRed,
  colorGreyShadow
} from "../variables";

interface Props extends SpaceProps, LayoutProps, TypographyProps {
  second?: boolean;
  third?: boolean;
  four?: boolean;
  five?: boolean;
  six?: boolean;
}

const Button = styled.button<Props>`
  display: block;
  width: 100%;
  height: 3rem;
  background: ${colorOrangeLight};
  border-radius: 1.5rem;
  font-size: 1rem;
  font-weight: 700;
  color: ${colorWhite};
  ${({second}) => second && `
    background: ${colorWhite};
    color: ${colorGrey};
  `}
  ${({third}) => third && `
    background: ${colorOrange};
    border-radius: .25rem;
    -webkit-box-shadow: 0px .25rem 0px 0px ${colorRed};
    box-shadow: 0px 4px 0px 0px ${colorRed};
    font-size: 1rem;
  `}
  ${({four}) => four && `
    background: ${colorGreyLight};
    border-radius: .25rem;
    -webkit-box-shadow: 0px .25rem 0px 0px ${colorGreyShadow};
    box-shadow: 0px 4px 0px 0px ${colorGreyShadow};
  `}
  ${({five}) => five && `
    background: ${colorOrange};
    height: 3.75rem;
    border-radius: 1.875rem;
    -webkit-box-shadow: 0px .25rem 0px 0px ${colorRed};
    box-shadow: 0px .25rem 0px 0px ${colorRed};
    border: 0.25rem solid #EF7737;
  `}
  ${space}
  ${layout}
  ${typography}
`;

export const RedirectButton = styled.a<Props>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  width: 100%;
  height: 3rem;
  background: ${colorOrangeLight};
  border-radius: 1.5rem;
  font-weight: 700;
  color: ${colorWhite};
  ${({second}) => second && `
    background: ${colorWhite};
    color: ${colorGrey};
  `}
  ${({third}) => third && `
    background-image: url(${btnOP});
    background-repeat: no-repeat;
    background-color: transparent;
    border-radius: 0;
    background-size: contain;
  `}
  ${({four}) => four && `
    background: ${colorGreyLight};
    border-radius: .25rem;
    -webkit-box-shadow: 0px .25rem 0px 0px ${colorGreyShadow};
    box-shadow: 0px 4px 0px 0px ${colorGreyShadow};
  `}
  ${({five}) => five && `
    background: ${colorOrange};
    height: 3.75rem;
    border-radius: 1.875rem;
    -webkit-box-shadow: 0px .25rem 0px 0px ${colorRed};
    box-shadow: 0px .25rem 0px 0px ${colorRed};
    border: 0.25rem solid #EF7737;
  `}
  ${space}
  ${layout}
  ${typography}
  ${({six}) => six && `
    background: ${colorOrange};
    -webkit-box-shadow: 0px .25rem 0px 0px ${colorRed};
    box-shadow: 0px .25rem 0px 0px ${colorRed};
    border-radius: .25rem;
    margin-top: 24px;
  `}
`;

export const HelpButton = styled.button<TypographyProps>`
  width: 16px;
  height: 16px;
  background: url(${iconHint}) no-repeat center center;
  background-size: contain;
`;

export const ButtonOutline = styled.button<Props>`
  display: block;
  width: 5.5rem;
  height: 2rem;
  background: transparent;
  border-radius: 1.5rem;
  font-weight: 700;
  color: ${colorWhite};
  border: 1px solid ${colorWhite};
  ${space}
`;

export const ButtonBuy = styled.div`
  display: block;
  width: 167px;
  height: 78px;
  background: url(${btnBuy}) no-repeat center center;
  background-size: contain;
  padding: 0;
  line-height: 1.5;
  margin-left: 4.5px;
`;

export const ButtonSell = styled.div`
  display: block;
  width: 167px;
  height: 78px;
  background: url(${btnSell}) no-repeat center center;
  font-weight: 700;
  color: ${colorWhite};
  background-size: contain;
  padding: 0;
  line-height: 1.5;
  margin-right: 4.5px;
`;

Button.defaultProps = {
 theme: {
   space: [0, 4, 8, 16, 24]
 }
};


export default Button;
