import React from 'react'
import dynamic from "next/dynamic";
import Spinner from '@src/components/Spinner';
import AppLayout from '@src/app/AppLayout';
const TopContainer = dynamic(() => import("@src/top/TopContainer"), {
  loading: () => <Spinner />,
  ssr: false
});
const Top: React.FC = () => {
  return (
    <AppLayout>
      <TopContainer />
      {(process.env.NEXT_APP_ENV === 'dev') && <div className="build-id">{process.env.BUILD_ID}</div>}
    </AppLayout>
  )
}
export default Top
