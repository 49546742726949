import styled from "styled-components";
import { fs, rem } from "../helpers";
import { colorGrey, colorWhite } from "../variables";

const Wrapper = styled.div`
  margin: 0 auto;
  width: calc(100% - 2rem);
  max-width: 480px;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  background: ${colorWhite};
  padding: ${rem(20)} 0 ${rem(16)};
  text-align: center;
  color: ${colorGrey};
  ${fs(16)};
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  border-bottom: 1px solid #E6E6E6;
  font-weight: 700;
  z-index: 999;
`;

interface BodyProps {
  readonly noBorder?: boolean;
  padding?: string;
}

const Body = styled.div<BodyProps>`
  background: ${colorWhite};
  padding: ${props => props.padding ? props.padding : "1.5rem 1rem 1rem 1rem"};
  color: ${colorGrey};
  ${fs(14)};
  border-top-left-radius: ${props => props.noBorder ? "0" : "0.5rem"};
  border-top-right-radius: ${props => props.noBorder ? "0" : "0.5rem"};
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  box-shadow: 0px 4px 12px rgba(0,0,0,0.2);
`;

const BodyWrapper = styled.div`
  text-align: center;
`;

const Paragraph = styled.p`
  color: ${colorGrey};
  ${fs(14)};
  display: flex;
  align-items: center;
  margin: 0 .4375rem;
`;

const OrderWrapper = styled.div`
  border-radius: .25rem;
  background: #F5F5F5;
  border: 1px solid #E9E9E9;
  margin-top: 1.5rem;
  padding: 0 .75rem;
`;

const WinLose = styled.div<{isWin: boolean}>`
  background: url(${({isWin}) =>isWin ? '/images/bg_win.png' : '/images/bg_lose.png'}) no-repeat;
  background-size: cover;
  color: ${colorWhite};
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 2.1875rem 1rem 1rem;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

const AmountChange = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: ${colorWhite};
  width: 100%;
  padding: .75rem 1rem 1rem;
  border-radius: .25rem;
`;

const OrderItem = styled.div`
  border-bottom: 1px solid #E9E9E9;
  padding: .5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  &:last-child {
    border-bottom: none;
  }
`;

const OrderItemNumber = styled.div`
  border-bottom: 1px solid #E9E9E9;
  padding: .5rem 0;
  display: flex;
  justify-content: center;
  align-items: baseline;
  font-size: 14px;
  &:last-child {
    border-bottom: none;
  }
`;

export default {
  Wrapper,
  Header,
  Body,
  BodyWrapper,
  Paragraph,
  OrderWrapper,
  OrderItem,
  OrderItemNumber,
  WinLose,
  AmountChange
};
