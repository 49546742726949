export const colorBlue = '#369BD1';
export const colorBlueLight = '#54B6EB';
export const colorGreyLight = '#999999';
export const colorPink = '#F05663';
export const colorDarkBrown = '#461C00';
export const colorWhite = '#FFFFFF';
export const colorOrangeLight = '#F57600';
export const colorOrange = '#EB5505';
export const colorGrey = '#4D4D4D';
export const colorGreyShadow = '#808080';
export const colorRed = '#D13B04'
