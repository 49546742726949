import Header from '@src/components/Header';
import React from 'react';
import Head from 'next/head'

const AppLayout: React.FC = ({ children }) => (
  <>
    <Head>
      <title>FX体験へようこそ</title>
      <link rel="icon" href="/favicon.ico" />
    </Head>
    <Header />
    {children}
  </>
);

export default (AppLayout);